// SessionInputView.tsx
import React, { useState } from 'react';
import { User } from 'firebase/auth';
import { addDoc, collection } from 'firebase/firestore';
import { db } from './BG/firebase';
import './CSS/SessionInputView.css';

interface SessionInputViewProps {
  user: User;
  onSaveSession: () => void;
  onGoBack: () => void;
}

const SessionInputView: React.FC<SessionInputViewProps> = ({ user, onSaveSession, onGoBack }) => {
  const [profit, setProfit] = useState('');

  const handleSaveSession = async () => {
    try {
      const sessionData = {
        userId: user.uid,
        profit: profit ? parseFloat(profit) : 0,
        duration: 0, 
        timestamp: new Date(),
      };
      await addDoc(collection(db, 'sessions'), sessionData);
      setProfit('');
      onSaveSession();
    } catch (error) {
      console.error('Error saving gambling session:', error);
    }
  };

  const handleGoBack = () => {
    onGoBack();
  };

  return (
    <div className="session-input-view">
      <div className="session-input-content">
        <input
          type="text"
          value={profit}
          onChange={(e) => setProfit(e.target.value)}
          placeholder="Enter Win/Loss"
          className="session-input-field"
          required
        />
      </div>
      <div className="session-input-footer">
        <button className="go-back-button" onClick={handleGoBack}>
          Go Back
        </button>
        <button className="save-session-button" onClick={handleSaveSession}>
          Save Session
        </button>
      </div>
    </div>
  );
};

export default SessionInputView;