// SessionsView.tsx
import React, { useEffect, useState } from 'react';
import { User } from 'firebase/auth';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from './BG/firebase';
import './CSS/SessionsView.css';
import IndividualSessionView from './IndividualSessionView';

interface SessionsViewProps {
  user: User;
  onSessionDeleted: () => void;
  refreshSessions: boolean;
}

interface SessionData {
  id: string;
  userId: string;
  profit: number;
  timestamp: {
    toDate(): Date;
  };
  duration: number;
}

const SessionsView: React.FC<SessionsViewProps> = ({ user, onSessionDeleted, refreshSessions }) => {
  const [storedSessions, setStoredSessions] = useState<SessionData[]>([]);
  const [selectedSession, setSelectedSession] = useState<SessionData | null>(null);

  const fetchStoredSessions = async () => {
    try {
      const sessionsQuery = query(collection(db, 'sessions'), where('userId', '==', user.uid));
      const sessionsSnapshot = await getDocs(sessionsQuery);
      const sessions = sessionsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as SessionData[];
      sessions.sort((a, b) => b.timestamp.toDate().getTime() - a.timestamp.toDate().getTime());
      setStoredSessions(sessions);
    } catch (error) {
      console.error('Error fetching stored sessions:', error);
    }
  };

  const formatHourlyRate = (profit: number, durationInMinutes: number) => {
    if (durationInMinutes === 0) {
      return;
    }
    const durationInHours = durationInMinutes / 60;
    const hourlyRate = profit / durationInHours;
    const formattedHourlyRate = Math.abs(hourlyRate).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
    return `${profit < 0 ? '-' : ''}${formattedHourlyRate}/hr`;
  };

  useEffect(() => {
    fetchStoredSessions();
  }, [user.uid, refreshSessions, fetchStoredSessions]);

  const formatDate = (date: Date) => {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear() % 100;
    return `${month}/${day}/${year}`;
  };

  const handleSessionClick = (session: SessionData) => {
    setSelectedSession(session);
  };

  const formatCurrency = (amount: number) => {
    if (isNaN(amount)) {
      return '';
    }
    const formattedAmount = Math.abs(amount).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    });
    return `${amount < 0 ? '-' : ''}${formattedAmount}`;
  };

  const handleCloseSession = () => {
    setSelectedSession(null);
  };

  return (
    <div className="sessions-view">
      {selectedSession ? (
        <IndividualSessionView
          session={selectedSession}
          onCloseSession={handleCloseSession}
          onSessionDeleted={onSessionDeleted}
        />
      ) : (
        <div className="session-list">
          {storedSessions.map((session) => (
            <div key={session.id} className="session-item" onClick={() => handleSessionClick(session)}>
              <div className="session-title">{formatDate(session.timestamp.toDate())}</div>
              <div className="session-hourly-rate">
                {formatHourlyRate(session.profit, session.duration)}
              </div>
              {!isNaN(session.profit) && (
                <div className={`session-profit ${session.profit >= 0 ? 'profit-positive' : 'profit-negative'}`}>
                  {formatCurrency(session.profit)}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SessionsView;