// App.tsx
import React, { useEffect, useState } from 'react';
import { onAuthStateChanged, User } from 'firebase/auth';
import { auth } from './BG/firebase';
import Login from './Login';
import './CSS/App.css';
import MainView from './MainView';
import navigationViewModel from './navigationViewModel';

const App: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });
    return () => unsubscribe();
  }, []);

  const handleSignOut = () => {
    setUser(null);
  };

  return (
    <div className="App">
      <header className="App-header">
        {user ? (
          <MainView user={user} navigationViewModel={navigationViewModel} onSignOut={handleSignOut} />
        ) : (
          <Login />
        )}
      </header>
    </div>
  );
};

export default App;