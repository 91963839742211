// MainView.tsx
import React, { useEffect, useState } from 'react';
import { User } from 'firebase/auth';
import { collection, query, where, onSnapshot, addDoc, serverTimestamp, getDocs, updateDoc } from 'firebase/firestore';
import { db } from './BG/firebase';
import navigationViewModel from './navigationViewModel';
import { observer } from 'mobx-react-lite';
import SessionInputView from './SessionInputView';
import SessionsView from './SessionsView';
import ProfitChart from './ProfitChart';
import { FiInfo } from 'react-icons/fi';
import SettingsView from './SettingsView';
import './CSS/MainView.css';

interface MainViewProps {
  user: User;
  navigationViewModel: typeof navigationViewModel;
  onSignOut: () => void;
}

const MainView: React.FC<MainViewProps> = observer(({ user, navigationViewModel, onSignOut }) => {
  const [totalProfit, setTotalProfit] = useState(0);
  const [chartData, setChartData] = useState<{ labels: string[]; data: number[] }>({ labels: [], data: [] });
  const [showSettings, setShowSettings] = useState(false);
  const [showSessionInputView, setShowSessionInputView] = useState(false);
  const [refreshSessions, setRefreshSessions] = useState(false);
  const [isClockedIn, setIsClockedIn] = useState(false);
  const [clockInTime, setClockInTime] = useState<Date | null>(null);
  const [totalDuration, setTotalDuration] = useState(0);
  const [hourlyRate, setHourlyRate] = useState(0);
  const [showHourlyRate, setShowHourlyRate] = useState(false);

    const toggleProfitHourlyRate = () => {
    setShowHourlyRate(!showHourlyRate);
  };


  useEffect(() => {
    const fetchClockInSession = async () => {
      try {
        const clockInSessionQuery = query(
          collection(db, 'clockInSessions'),
          where('userId', '==', user.uid),
          where('clockOutTime', '==', null)
        );
        const clockInSessionSnapshot = await getDocs(clockInSessionQuery);

        if (!clockInSessionSnapshot.empty) {
          const clockInSession = clockInSessionSnapshot.docs[0].data();
          setIsClockedIn(true);
          setClockInTime(clockInSession.clockInTime.toDate());
        }
      } catch (error) {
        console.error('Error fetching clock-in session:', error);
      }
    };

    fetchClockInSession();
  }, [user.uid]);

  useEffect(() => {
    const sessionsQuery = query(collection(db, 'sessions'), where('userId', '==', user.uid));
    const unsubscribe = onSnapshot(sessionsQuery, (snapshot) => {
      let totalProfit = 0;
      const labels: string[] = [];
      const data: number[] = [];
      let runningTotal = 0;
  
      const sessions = snapshot.docs.map((doc) => doc.data());
      sessions.sort((a, b) => {
        const timestampA = a.timestamp?.toDate();
        const timestampB = b.timestamp?.toDate();
        if (timestampA && timestampB) {
          return timestampA.getTime() - timestampB.getTime();
        } else if (timestampA) {
          return -1;
        } else if (timestampB) {
          return 1;
        } else {
          return 0;
        }
      });
  
      sessions.forEach((session) => {
        const profit = session.profit;
        if (typeof profit === 'number' && !isNaN(profit)) {
          totalProfit += profit;
          runningTotal += profit;
          if (session.timestamp) {
            labels.push(formatDate(session.timestamp.toDate()));
            data.push(runningTotal);
          }
        }
      });
  
      setTotalProfit(totalProfit);
      setChartData({ labels, data });
    });
  
    return () => unsubscribe();
  }, [user.uid]);

  useEffect(() => {
    const sessionsQuery = query(collection(db, 'sessions'), where('userId', '==', user.uid));
    const unsubscribe = onSnapshot(sessionsQuery, (snapshot) => {
      let totalProfit = 0;
      let totalDurationWithProfit = 0;
  
      const sessions = snapshot.docs.map((doc) => doc.data());
      sessions.forEach((session) => {
        const profit = session.profit;
        const duration = session.duration;
        if (typeof duration === 'number' && !isNaN(duration) && duration !== 0 && typeof profit === 'number' && !isNaN(profit)) {
          totalProfit += profit;
          totalDurationWithProfit += duration;
        }
      });
  
      setHourlyRate(totalDurationWithProfit !== 0 ? totalProfit / totalDurationWithProfit : 0);
    });
  
    return () => unsubscribe();
  }, [user.uid]);

  useEffect(() => {
    console.log('Hourly Rate:', formatCurrency(hourlyRate));
  }, [hourlyRate]);

  const handleClockIn = async () => {
    try {
      await addDoc(collection(db, 'clockInSessions'), {
        userId: user.uid,
        clockInTime: serverTimestamp(),
        clockOutTime: null,
      });
      setIsClockedIn(true);
      setClockInTime(new Date());
    } catch (error) {
      console.error('Error clocking in:', error);
    }
  };

  const handleClockOut = async () => {
    if (clockInTime) {
      const durationInMinutes = Math.round((new Date().getTime() - clockInTime.getTime()) / 60000); // Convert milliseconds to minutes
  
      try {
        const clockInSessionQuery = query(
          collection(db, 'clockInSessions'),
          where('userId', '==', user.uid),
          where('clockOutTime', '==', null)
        );
        const clockInSessionSnapshot = await getDocs(clockInSessionQuery);
  
        if (!clockInSessionSnapshot.empty) {
          const clockInSessionDoc = clockInSessionSnapshot.docs[0];
          await updateDoc(clockInSessionDoc.ref, {
            clockOutTime: serverTimestamp(),
            duration: durationInMinutes,
          });
  
          await addDoc(collection(db, 'sessions'), {
            userId: user.uid,
            profit: 0,
            duration: durationInMinutes,
            timestamp: serverTimestamp(),
          });
  
          setIsClockedIn(false);
          setClockInTime(null);
          setRefreshSessions(!refreshSessions);
        }
      } catch (error) {
        console.error('Error clocking out and saving session:', error);
      }
    }
  };

  const formatDuration = (durationInMinutes: number) => {
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = durationInMinutes % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  };
  
  useEffect(() => {
    const sessionsQuery = query(collection(db, 'sessions'), where('userId', '==', user.uid));
    const unsubscribe = onSnapshot(sessionsQuery, (snapshot) => {
      let totalProfit = 0;
      const labels: string[] = [];
      const data: number[] = [];
      let runningTotal = 0;
  
      const sessions = snapshot.docs.map((doc) => doc.data());
      sessions.sort((a, b) => {
        const timestampA = a.timestamp?.toDate();
        const timestampB = b.timestamp?.toDate();
        if (timestampA && timestampB) {
          return timestampA.getTime() - timestampB.getTime();
        } else if (timestampA) {
          return -1;
        } else if (timestampB) {
          return 1;
        } else {
          return 0;
        }
      });
  
      sessions.forEach((session) => {
        const profit = session.profit;
        if (typeof profit === 'number' && !isNaN(profit)) {
          totalProfit += profit;
          runningTotal += profit;
          if (session.timestamp) {
            labels.push(formatDate(session.timestamp.toDate()));
            data.push(runningTotal);
          }
        }
      });
  
      setTotalProfit(totalProfit);
      setChartData({ labels, data });
    });
  
    return () => unsubscribe();
  }, [user.uid]);

  const handleNewSession = () => {
    setShowSessionInputView(true);
  };

  const handleSaveSession = () => {
    setShowSessionInputView(false);
    setRefreshSessions(!refreshSessions);
  };


  const handleGoBack = () => {
    setShowSessionInputView(false);
  };

  const formatCurrency = (amount: number) => {
    const formattedAmount = Math.abs(amount).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
    return `${formattedAmount}`;
  };

  const formatDate = (date: Date) => {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear() % 100;
    return `${month}/${day}/${year}`;
  };

  const handleSessionDeleted = () => {
    // Refresh the chart data or perform any necessary actions after a session is deleted
  };

  const toggleSettings = () => {
    setShowSettings(!showSettings);
  };

  const handleSignOut = () => {
    onSignOut();
  };


  if (showSettings) {
    return <SettingsView user={user} onClose={toggleSettings} onSignOut={handleSignOut} />;
  }

  return (
    <div className="main-view">
      <div className="main-view-header">
        <div className="header-content">
        <h2
            className={showHourlyRate ? 'hourly-rate' : totalProfit >= 0 ? 'profit-positive' : 'profit-negative'}
            onClick={toggleProfitHourlyRate}
          >
            {showHourlyRate ? `${formatCurrency(hourlyRate)}/hr` : formatCurrency(totalProfit)}
          </h2>
          <div className="info-icon-wrapper" onClick={toggleSettings}>            <FiInfo className="info-icon" />
          </div>
        </div>
      </div>
      <div className="main-view-chart">
        <ProfitChart labels={chartData.labels} data={chartData.data} />
      </div>
      <div className="main-view-content">
         <SessionsView user={user} onSessionDeleted={handleSessionDeleted} refreshSessions={refreshSessions} />
      </div>
      <div className="main-view-footer">
      {!showSessionInputView && (
        <>
          {isClockedIn ? (
            <button className="main-view-button clock-out-button" onClick={handleClockOut}>
              Clock Out ({formatDuration((new Date().getTime() - (clockInTime?.getTime() || 0)) / 3600000)})
            </button>
          ) : (
            <button className="main-view-button clock-in-button" onClick={handleClockIn}>
              Clock In
            </button>
          )}
        </>
      )}
      {showSessionInputView ? (
        <SessionInputView user={user} onSaveSession={handleSaveSession} onGoBack={handleGoBack} />
      ) : (
        <button className="main-view-button new-session-button" onClick={handleNewSession}>
          New Session
        </button>
      )}
    </div>
    </div>
  );
});

export default MainView;