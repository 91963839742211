// IndividualSessionView.tsx
import React, { useState , useRef} from 'react';
import { doc, deleteDoc, updateDoc } from 'firebase/firestore';
import { db } from './BG/firebase';
import './CSS/IndividualSessionView.css';

interface IndividualSessionViewProps {
  session: {
    id: string;
    profit: number;
    timestamp: {
      toDate(): Date;
    };
    duration: number;
  };
  onCloseSession: () => void;
  onSessionDeleted: () => void;
}

const IndividualSessionView: React.FC<IndividualSessionViewProps> = ({ session, onCloseSession, onSessionDeleted }) => {
  const [hours, setHours] = useState(Math.floor(session.duration / 60));
  const [minutes, setMinutes] = useState(session.duration % 60);
  const [profit, setProfit] = useState(session.profit.toString());
  const [editingProfit, setEditingProfit] = useState(false);
  const [editingDuration, setEditingDuration] = useState(false);
  const [saveStatus, setSaveStatus] = useState('');
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const durationInputRef = useRef<HTMLDivElement>(null);

  const formatDate = (date: Date) => {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear() % 100;
    return `${month}/${day}/${year}`;
  };

  const formatCurrency = (amount: number) => {
    if (isNaN(amount)) {
      return '';
    }
    const formattedAmount = Math.abs(amount).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return `${formattedAmount}`;
  };

  const formatDuration = (hours: number, minutes: number) => {
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  };

  const handleDeleteSession = async () => {
    setDeleteConfirmation(true);
  };

  const handleDeleteConfirmation = async (confirmed: boolean) => {
    if (confirmed) {
      try {
        await deleteDoc(doc(db, 'sessions', session.id));
        onSessionDeleted();
        onCloseSession();
      } catch (error) {
        console.error('Error deleting session:', error);
      }
    } else {
      setDeleteConfirmation(false);
    }
  };

  const handleHoursChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value);
    setHours(value);
  };

  const handleMinutesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value);
    setMinutes(value);
  };

  const handleProfitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProfit(event.target.value);
  };

  const handleProfitBlur = () => {
    setEditingProfit(false);
  };

  const handleDurationBlur = (event: React.FocusEvent<HTMLDivElement>) => {
    if (
      durationInputRef.current &&
      !durationInputRef.current.contains(event.relatedTarget as Node)
    ) {
      setEditingDuration(false);
    }
  };

  const handleSaveChanges = async () => {
    try {
      const updatedProfit = parseFloat(profit);
      const updatedDuration = hours * 60 + minutes;
      await updateDoc(doc(db, 'sessions', session.id), {
        profit: isNaN(updatedProfit) ? session.profit : updatedProfit,
        duration: updatedDuration,
      });
      setSaveStatus('Saved');
      setTimeout(() => {
        setSaveStatus('');
      }, 2000);
    } catch (error) {
      console.error('Error updating session:', error);
    }
  };

  const handleBackClick = () => {
    onCloseSession();
  };

  return (
    <div className="individual-session-view">
      <div className="session-details">
        <div className="session-info">
          <div className="session-label">Profit:</div>
          {editingProfit ? (
            <input
              type="text"
              inputMode="decimal"
              value={profit}
              onChange={handleProfitChange}
              onBlur={handleProfitBlur}
              autoFocus
            />
          ) : (
            <div className="session-value" onClick={() => setEditingProfit(true)}>
              {formatCurrency(parseFloat(profit))}
            </div>
          )}
        </div>
        <div className="session-info">
          <div className="session-label">Date:</div>
          <div className="session-value">{formatDate(session.timestamp.toDate())}</div>
        </div>
        <div className="session-info">
  <div className="session-label">Duration:</div>
  {editingDuration ? (
    <div
      className="duration-input-container"
      onBlur={handleDurationBlur}
      ref={durationInputRef}
    >
      <div className="duration-input-wrapper">
        <input
          type="number"
          inputMode="numeric"
          value={hours}
          onChange={handleHoursChange}
          autoFocus
        />
        <span className="duration-label">Hours</span>
      </div>
      <span className="duration-separator">:</span>
      <div className="duration-input-wrapper">
        <input
          type="number"
          value={minutes}
          onChange={handleMinutesChange}
        />
        <span className="duration-label">Minutes</span>
      </div>
    </div>
  ) : (
    <div className="session-value" onClick={() => setEditingDuration(true)}>
      {formatDuration(hours, minutes)}
    </div>
  )}
</div>
      </div>
      <div className="session-details-buttons">
        {deleteConfirmation ? (
          <>
            <button className="save-button" onClick={() => handleDeleteConfirmation(true)}>
              Yes
            </button>
            <button className="delete-button" onClick={() => handleDeleteConfirmation(false)}>
              No
            </button>
          </>
        ) : (
          <>
            <button className={`save-button ${saveStatus ? 'saved' : ''}`} onClick={handleSaveChanges}>
              {saveStatus || 'Save'}
            </button>
            <button className="delete-button" onClick={handleDeleteSession}>
              Delete
            </button>
          </>
        )}
      </div>
      <button className="back-button" onClick={handleBackClick}>
        Back
      </button>
    </div>
  );
};

export default IndividualSessionView;