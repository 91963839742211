import { makeAutoObservable } from 'mobx';

class NavigationViewModel {
  showStoredSessions = false;
  showSessionInputView = false;

  constructor() {
    makeAutoObservable(this);
  }

  setShowStoredSessions(value: boolean) {
    this.showStoredSessions = value;
  }

  setShowSessionInputView(value: boolean) {
    this.showSessionInputView = value;
  }
}

const navigationViewModel = new NavigationViewModel();

export default navigationViewModel;
