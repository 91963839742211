// ProfitChart.tsx
import React from 'react';
import { Line } from 'react-chartjs-2';
import { CategoryScale, Chart, LinearScale, PointElement, LineElement } from 'chart.js';
import './CSS/ProfitChart.css';

Chart.register(CategoryScale, LinearScale, PointElement, LineElement);

interface ProfitChartProps {
  labels: string[];
  data: number[];
}

const ProfitChart: React.FC<ProfitChartProps> = ({ labels, data }) => {
  const chartOptions = {
    responsive: true,
    scales: {
      x: {
        display: false,
        title: {
          display: false,
        },
      },
      y: {
        display: false,
        title: {
          display: false,
        },
      },
    },
    elements: {
      line: {
        borderColor: '#FFA500',
      },
      point: {
        borderColor: '#FFA500',
        backgroundColor: '#2E8BC0',
      },
    },
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          title: (context: any) => {
            return context[0].label;
          },
          label: (context: any) => {
            return `Profit: ${context.parsed.y}`;
          },
        },
      },
    },
    backgroundColor: '#F0F0F0', // Set the background color to light gray
  };

  return (
    <div className="profit-chart">
      <Line
        data={{
          labels,
          datasets: [{ label: 'Profit', data, borderColor: '#FF7F50' }],
        }}
        options={chartOptions}
      />
    </div>
  );
};

export default ProfitChart;