// SettingsView.tsx
import React, { useState } from 'react';
import { User } from 'firebase/auth';
import { collection, query, where, getDocs, writeBatch } from 'firebase/firestore';
import { auth, db } from './BG/firebase';
import { signOut } from 'firebase/auth';
import './CSS/SettingsView.css';

interface SettingsViewProps {
  user: User;
  onClose: () => void;
  onSignOut: () => void;
}

const SettingsView: React.FC<SettingsViewProps> = ({ user, onClose, onSignOut }) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const handleDeleteAllSessions = async () => {
    try {
      const sessionsQuery = query(collection(db, 'sessions'), where('userId', '==', user.uid));
      const sessionsSnapshot = await getDocs(sessionsQuery);
      const batch = writeBatch(db);

      sessionsSnapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });

      await batch.commit();
      // Refresh the chart data or perform any necessary actions after deleting all sessions
      onClose();
    } catch (error) {
      console.error('Error deleting all sessions:', error);
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      onSignOut();
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleDeleteConfirmation = () => {
    setShowDeleteConfirmation(true);
  };

  const openLink = (url: string) => {
    window.open(url, '_blank');
  };

  const handleDeleteCancel = () => {
    setShowDeleteConfirmation(false);
  };

  return (
    <div className="settings-view">
      <h2>Settings</h2>
      {/* Add your settings content here */}
      {showDeleteConfirmation && (
        <div className="delete-confirmation">
          <p>Are you sure you want to delete all sessions?</p>
          <div className="delete-confirmation-buttons">
            <button className="delete-yes-button" onClick={handleDeleteAllSessions}>
              Yes
            </button>
            <button className="delete-no-button" onClick={handleDeleteCancel}>
              No
            </button>
          </div>
        </div>
      )}
       <button className="contact-button" onClick={() => openLink('mailto:anthony@pulcer.net')}>
        Contact Me
      </button>
      <button className="website-button" onClick={() => openLink('https://pulcer.net/')}>
        Website
      </button>
      <button className="twitter-button" onClick={() => openLink('https://twitter.com/AnthonyHowell88')}>
        Follow me on X
      </button>
      <button className="delete-all-button" onClick={handleDeleteConfirmation}>
        Delete All Sessions
      </button>
      <button className="sign-out-button" onClick={handleSignOut}>
        Sign Out
      </button>
       <button className="close-settings-button" onClick={onClose}>
        Close Settings
      </button>
    </div>
  );
};

export default SettingsView;