// Login.tsx
import React from 'react';
import { signInWithPopup } from 'firebase/auth';
import { auth, googleProvider } from './BG/firebase';
import './CSS/Login.css';

const Login: React.FC = () => {
  const handleGoogleSignIn = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
      // Handle successful login, such as redirecting to a protected page
    } catch (error) {
      // Handle login error
      console.error('Error during Google Sign-In:', error);
    }
  };

  return (
    <div className="login-container">
      <div className="sign-in-container">
        <div className="arrow arrow-top"></div>
        <div className="arrow arrow-left"></div>
        <button className="login-button" onClick={handleGoogleSignIn}>
          Sign in with Google
        </button>
        <div className="arrow arrow-right"></div>
        <div className="arrow arrow-bottom"></div>
      </div>
    </div>
  );
};

export default Login;